import { bool, func, oneOf, string } from "prop-types";
import { forwardRef } from "react";

import classnames from "~utils/classnames";

import styles from "./Toggle.module.scss";

/**
 * @typedef {Object} ToggleProps
 * @property {string} id - The id of the input element
 * @property {string} name - The name of the input element
 * @property {string} [label] - The label for the toggle
 * @property {string} [variant] - The variant of the toggle
 * @property {function} [onChange] - The function to call when the toggle is changed
 * @property {boolean} [checked] - The checked state of the toggle
 * @property {boolean} [disabled] - The disabled state of the toggle
 * @property {string} [ariaLabel] - The aria label for the toggle
 * @property {string} [className] - The class name to add to the toggle
 */

/**
 * Toggle Component
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<ToggleProps> & React.RefAttributes<HTMLInputElement>>}
 */

const Toggle = forwardRef(
  (
    {
      id,
      name,
      label,
      variant = "primary",
      onChange,
      checked,
      disabled = false,
      ariaLabel,
      className,
    },
    ref,
  ) => {
    function handleOnChange(e) {
      if (onChange) {
        onChange(e.target.checked);
      }
    }

    const toggleClass = classnames(styles.toggle, styles[variant], className);

    return (
      <label className={styles.container} htmlFor={id}>
        <div className={toggleClass}>
          <input
            id={id}
            name={name}
            type="checkbox"
            role="switch"
            checked={checked}
            disabled={disabled}
            aria-labelledby={ariaLabel || name}
            aria-checked={checked}
            onChange={handleOnChange}
            ref={ref}
          />
        </div>
        {label && (
          <span id={ariaLabel || name} className={styles.label}>
            {label}
          </span>
        )}
      </label>
    );
  },
);

Toggle.displayName = "Toggle";
Toggle.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  label: string,
  variant: oneOf(["primary", "theme"]),
  onChange: func,
  checked: bool,
  disabled: bool,
  ariaLabel: string,
  className: string,
};

export { Toggle };

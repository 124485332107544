import { useTranslation } from "next-i18next";
import { arrayOf, func, shape, string } from "prop-types";

import Link from "~components/Link";
import HighlightLink from "~modules/Header/components/HighlightLink";
import TextLink from "~modules/Header/components/TextLink";
import ChevronLeft from "~public/icons/chevron-left.svg";
import classnames from "~utils/classnames";
import { toValidUrl } from "~utils/routing";

import classes from "../MobileMenu.module.scss";

const SubMenu = ({ activeSubMenu, headerItems, onBackClick }) => {
  const { t } = useTranslation();
  return (
    <div
      id={`subMenu-${activeSubMenu?.id}`}
      className={classnames(
        classes.subMenu,
        activeSubMenu && classes.subMenu_open,
      )}
    >
      <button className={classes.subMenuBack} onClick={onBackClick}>
        <ChevronLeft />
        {t("back")}
      </button>
      {headerItems.map((headerItem) =>
        headerItem.items.map((list) => {
          const url = toValidUrl(list.slug);
          return (
            <nav
              key={list.id}
              className={classnames(
                classes.navItemContent,
                activeSubMenu?.id === headerItem.id &&
                  classes.navItemContent_active,
              )}
              aria-hidden={activeSubMenu?.id !== headerItem.id}
            >
              {url ? (
                <Link
                  href={url}
                  key={list.id}
                  referenceId={list.id}
                  className={classes.navItemTitle}
                >
                  {list.title}
                </Link>
              ) : (
                <div className={classes.navItemTitle}>{list.title}</div>
              )}
              {list.items.map((listItem) =>
                list.showAsHighlights ? (
                  <HighlightLink
                    key={listItem.id}
                    title={listItem.shortTitle || listItem.title}
                    slug={listItem.slug}
                    image={listItem?.image?.[0]}
                  />
                ) : (
                  <TextLink
                    key={listItem.id}
                    title={listItem.title}
                    slug={listItem.slug}
                  />
                ),
              )}
              {list.externalLinks?.map((externalLink) => (
                <Link
                  key={externalLink.id}
                  className={classes.navItemLink}
                  href={externalLink.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  external
                >
                  {externalLink.label}
                </Link>
              ))}
            </nav>
          );
        }),
      )}
    </div>
  );
};

SubMenu.propTypes = {
  activeSubMenu: shape({
    id: string.isRequired,
  }).isRequired,
  headerItems: arrayOf(
    shape({
      id: string,
      items: arrayOf(
        shape({
          id: string,
          slug: string,
          title: string,
        }),
      ),
    }).isRequired,
  ).isRequired,
  onBackClick: func.isRequired,
};

export default SubMenu;

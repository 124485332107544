import { useRouter } from "next/router";
import { number, shape, string } from "prop-types";
import { useState } from "react";

import ImageComponent from "~components/ImageComponent";
import Link from "~components/Link";
import { ImageType } from "~types";
import { toValidUrl } from "~utils/routing";

import classes from "./HighlightLink.module.scss";

const HighlightLink = ({ title, slug, image, tabIndex }) => {
  const router = useRouter();
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);
  const handleClick = () => router.push(toValidUrl(`/${slug}`));

  return (
    <div className={classes.highlight}>
      {image && (
        <div
          className={classes.image}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <ImageComponent
            {...image}
            width={132}
            height={82}
            hideAuthor
            priority
          />
        </div>
      )}
      <Link
        href={toValidUrl(`/${slug}`)}
        className={classes.title}
        hover={hover}
        aria-label={title}
        tabIndex={tabIndex}
      >
        {title}
      </Link>
    </div>
  );
};

HighlightLink.propTypes = {
  title: string.isRequired,
  slug: string.isRequired,
  image: shape(ImageType),
  tabIndex: number,
};

export default HighlightLink;

import React from "react";

import Layout from "~components/Layout";

/**
 * HOC that applies the layout to a page.
 * If a page sometimes needs to render without the layout,
 * you can pass the disableLayout prop to the page propTypes property.
 *
 * @param {function} Component - Component to be wrapped.
 * @param {function} [CustomLayout] - A React component to replace the default Layout.
 * @return {function}
 */
export const withLayout = (Component, CustomLayout) => {
  const AppliedLayout = CustomLayout || Layout;

  // eslint-disable-next-line react/display-name
  Component.getLayout = (page, pageProps) =>
    pageProps?.disableLayout ? (
      page
    ) : (
      <AppliedLayout {...pageProps}>{page}</AppliedLayout>
    );
  return Component;
};

export default withLayout;

import { func, node, number } from "prop-types";
import React, { useEffect, useRef } from "react";

import ChevronDown from "~public/icons/chevron-down.svg";

import classes from "../Header.module.scss";

const NavigationItem = ({
  children,
  setActiveNavItem,
  tabIndex,
  onKeyDown,
}) => {
  const itemRef = useRef();

  useEffect(() => {
    const element = itemRef.current;

    const onMouseEnter = () => {
      setActiveNavItem();
    };

    element.addEventListener("mouseenter", onMouseEnter);
    return () => {
      element.removeEventListener("mouseenter", onMouseEnter);
    };
  }, [setActiveNavItem]);

  return (
    <div ref={itemRef} className={classes.desktopMenuItem}>
      {children}
      <div
        className={classes.chevronTrigger}
        role="button"
        aria-label="Expand menu"
        onClick={setActiveNavItem}
        tabIndex={tabIndex}
        onKeyDown={onKeyDown}
      >
        <ChevronDown />
      </div>
    </div>
  );
};

NavigationItem.propTypes = {
  children: node,
  setActiveNavItem: func,
  onKeyDown: func,
  tabIndex: number,
};

export default NavigationItem;

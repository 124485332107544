import { useRouter } from "next/router";
import { oneOf } from "prop-types";
import React, { useContext, useMemo } from "react";

import localeStringConverter from "~common/localeStringConverter";
import LocaleSelectDesktop from "~components/LocaleSelectDesktop";
import LocaleSelectMobile from "~components/LocaleSelectMobile";
import { SeoContext } from "~contexts/SeoContext";
import { resolveUrl } from "~utils/routing";

const LocaleSelect = ({ type, ...props }) => {
  const { localizations } = useContext(SeoContext);
  const router = useRouter();

  const localizationsMap = useMemo(() => {
    const map = new Map();
    localizations?.forEach(({ locale, slug }) => {
      const lang = localeStringConverter.toLang(locale);
      if (lang) {
        map.set(
          lang,
          resolveUrl(`/${slug}`, {
            locale: lang,
            defaultLocale: router.defaultLocale,
          }),
        );
      }
    });
    return map;
  }, [router.defaultLocale, localizations]);

  switch (type) {
    case "desktop":
      return (
        <LocaleSelectDesktop localizationsMap={localizationsMap} {...props} />
      );
    case "mobile":
      return <LocaleSelectMobile localizationsMap={localizationsMap} />;
  }
};

LocaleSelect.propTypes = {
  type: oneOf(["desktop", "mobile"]).isRequired,
};

export default LocaleSelect;

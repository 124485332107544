import { useRouter } from "next/router";
import { bool, func } from "prop-types";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import FavouriteButton from "~components/FavouriteButton";
import Link from "~components/Link";
import SearchButton from "~components/SearchButton";
import { NavigationContext } from "~contexts/NavigationContext";
import NavigationItem from "~modules/Header/components/NavigationItem";
import ShopCheckoutButton from "~modules/ShopCheckoutButton";
import classnames from "~utils/classnames";
import { focusTrap } from "~utils/focusTrap";
import { toValidUrl } from "~utils/routing";

import classes from "./DesktopMenu.module.scss";
import SubMenu from "./SubMenu";

const DesktopMenu = ({ showMenu, setShowMenu, fadeContent }) => {
  const navigationRef = useRef(null);
  const [activeMenuItemId, setActiveMenuItemId] = useState(null);
  const { currentDomain } = useContext(NavigationContext);
  const router = useRouter();
  const delayRef = useRef(null);

  const headerItems = currentDomain?.headerItems?.filter(Boolean) ?? [];

  const handleSetMenuItemId = useCallback(
    (headerItemId) => {
      if (headerItemId) {
        clearTimeout(delayRef.current);
        setActiveMenuItemId(headerItemId);
      }
      delayRef.current = setTimeout(() => {
        setShowMenu(!!headerItemId);
      }, 500);
    },
    [setShowMenu],
  );

  const clearDelay = () => clearTimeout(delayRef.current);

  const handleMouseLeave = () => {
    clearDelay();
    handleSetMenuItemId(null);
  };

  useEffect(() => {
    const element = navigationRef.current;
    element.addEventListener("mouseenter", clearDelay);
    element.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      element.removeEventListener("mouseenter", clearDelay);
      element.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [handleSetMenuItemId]);

  useEffect(() => {
    const handleRouteChangeStart = () => handleSetMenuItemId(null);

    router.events.on("routeChangeStart", handleRouteChangeStart);
    return () => router.events.off("routeChangeStart", handleRouteChangeStart);
  }, [router.events, handleSetMenuItemId]);

  const handleKeyDown = (e, item) => {
    if (["Enter", " "].includes(e.key)) {
      e.preventDefault();

      if (activeMenuItemId === item.id && showMenu) {
        setShowMenu(false);
        setActiveMenuItemId(null);
      } else {
        handleSetMenuItemId(item.id);
      }
    } else if (e.key === "Escape") {
      handleSetMenuItemId(null);
    }
  };

  useEffect(() => {
    if (!showMenu || !activeMenuItemId || !navigationRef.current) {
      return;
    }
    const navigationElement = navigationRef.current;
    const subMenuElement = navigationElement.querySelector(
      `[data-submenu-id="${activeMenuItemId}"]`,
    );

    setTimeout(() => {
      if (subMenuElement) {
        subMenuElement.focus();
      }
    }, 0);
  }, [showMenu, activeMenuItemId]);

  useEffect(() => {
    if (!showMenu) {
      return;
    }

    const destroyFocusTrap = focusTrap(navigationRef.current, {
      onEscape: () => setShowMenu(false),
    });

    return () => {
      destroyFocusTrap();
    };
  }, [showMenu, setShowMenu]);

  return (
    <div
      ref={navigationRef}
      role="navigation"
      aria-label="Main navigation"
      className={classnames(
        classes.desktopNavigation,
        fadeContent && classes.desktopNavigation_fade,
      )}
    >
      {headerItems.map((item) => (
        <NavigationItem
          key={item.id}
          setActiveNavItem={() => handleSetMenuItemId(item.id)}
          aria-haspopup="true"
          aria-expanded={activeMenuItemId === item.id}
          aria-controls={`submenu-${item.id}`}
          tabIndex={0}
          onKeyDown={(e) => handleKeyDown(e, item)}
        >
          <Link href={toValidUrl(item.slug)}>{item.title}</Link>
        </NavigationItem>
      ))}
      <div className={classes.cta}>
        {currentDomain.showOrderButton && <ShopCheckoutButton />}
        {currentDomain.showFavouritesButton && <FavouriteButton />}
        <SearchButton />
      </div>
      <div
        className={classnames(
          classes.desktopMenu,
          showMenu && classes.desktopMenu_open,
        )}
        aria-hidden={!showMenu}
      >
        <div className={classes.contentWrapper}>
          {showMenu &&
            headerItems.map((headerItem) =>
              headerItem.items.map((list) => (
                <SubMenu
                  key={list.id}
                  list={list}
                  isActive={activeMenuItemId === headerItem.id}
                  submenuDataId={headerItem.id}
                />
              )),
            )}
        </div>
      </div>
    </div>
  );
};

DesktopMenu.propTypes = {
  showMenu: bool.isRequired,
  setShowMenu: func.isRequired,
  fadeContent: bool,
};

export default DesktopMenu;

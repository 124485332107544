import Image from "next/legacy/image";
import { useTranslation } from "next-i18next";
import { bool, number, oneOf, string } from "prop-types";
import React from "react";

import classes from "./ImageComponent.module.scss";

const ImageComponent = ({
  layout,
  src,
  alt,
  width,
  height,
  sizes,
  objectFit,
  priority,
  author,
  copyright,
  hideAuthor,
  title,
}) => {
  const { t } = useTranslation();

  const onImageLoadError = (event) => {
    // for some reason sometimes netlify's IPX proxy throws 500 errors
    // as a fallback use the original image (from external source)
    // see: https://answers.netlify.com/t/ipx-image-load-not-working/87627/2
    if (event.target instanceof HTMLImageElement && event.target.src !== src) {
      console.warn(
        `Image src load error: ${event.target.src}, using fallback: ${src}`,
      );
      event.target.src = src;
      event.target.srcset = "";
    }
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== "production") {
      console.error(event);
    }
  };

  switch (layout) {
    case "fill":
      return (
        <>
          <div
            className={classes.feedfactory_container}
            style={{
              height: `${height}px`,
            }}
          >
            <Image
              src={src}
              alt={alt ?? title ?? undefined}
              sizes={sizes}
              layout="fill"
              objectFit={objectFit}
              priority={priority}
              onError={onImageLoadError}
            />
          </div>
          {copyright && !hideAuthor && (
            <div className={classes.author}>
              {t("imageFrom", { copyright })}
            </div>
          )}
        </>
      );
    default:
      return (
        <>
          <div className={classes.prepr_container}>
            <Image
              src={src}
              alt={alt}
              width={width}
              height={height}
              sizes={sizes}
              objectFit={objectFit}
              priority={priority}
              layout={layout}
            />
          </div>
          {author && !hideAuthor && (
            <div className={classes.author}>
              {t("imageFrom", { copyright: author })}
            </div>
          )}
        </>
      );
  }
};

ImageComponent.propTypes = {
  layout: oneOf(["fill", "fixed", "intrinsic", "responsive"]),
  src: string.isRequired,
  alt: string, // alt should be required, but it's a cms issue
  width: number.isRequired,
  height: number.isRequired,
  sizes: string,
  objectFit: oneOf(["cover", "contain", "fill", "none", "scale-down"]),
  author: string,
  copyright: string,
  priority: bool,
  hideAuthor: bool,
  title: string,
};

export default ImageComponent;

import { useTranslation } from "next-i18next";

import styles from "./SkipLink.module.scss";

const SkipLink = () => {
  const { t } = useTranslation();

  return (
    <a
      href="#main-content"
      className={styles.skipLink}
      aria-label={t("skiplink")}
    >
      {t("skiplink")}
    </a>
  );
};

export default SkipLink;
